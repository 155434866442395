const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/8.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/9.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/10.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/11.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/12.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/13.png",
    text: "13",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/14.png",
    text: "14",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/15.png",
    text: "15",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/16.png",
    text: "16",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/17.png",
    text: "17",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/18.png",
    text: "18",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/19.png",
    text: "19",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/20.png",
    text: "20",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/21.png",
    text: "21",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/22.png",
    text: "22",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/23.png",
    text: "23",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/24.png",
    text: "24",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/25.png",
    text: "25",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/varunagarwal/26.png",
    text: "26",
  },
];

export default data;
