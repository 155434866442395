import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Varun Agrawal",
  address: "Model Town New Delhi",
  client: "Varun Agrawal",
  area: "4,000 Sq. Ft.",
  project: "Residential Interior",
  category: " Interiors | Residential",
  backlink: "/projects/interiors/",
};

export default class Projectvarunagrawal extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Interior Projects | Varun Agrawal, Model Town"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore a showcase of photos of the luxurious contemporary interiors designed for a builder floor for a leading developer."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
